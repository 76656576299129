import React, { Component } from "react";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <div style={{ margin: "10px" }} className="center-wrapper">
        <h1>Not Found :(</h1>
      </div>
    );
  }
}

export default NotFound;
